import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useCurrentOrg } from 'auth/hooks'
import useProfile from 'champion/hooks/useProfile'
import Loading from 'components/Loading'
import ProfileInfoCard from 'champion/components/ProfileInfoCard'
import { ChampionProfile } from 'champion/interfaces'
import { useBreakPoint } from 'civic-champs-shared/helpers/useBreakpoint'
import { ProfileAccordion } from 'champion/pages/ProfileAccordion'
import { ProfileTabs } from './ProfileTabs'
import PageHeader from 'App/components/PageHeader'
import { useGroup } from '../../group/hooks/useGroup'
import { Group } from '../../Event/components/opportunity/GroupPicker/types'
import { useMembers } from '../../group/hooks/useMembers'
import { isNil } from 'lodash'
import { ContainedButton, OutlinedButton } from '../../civic-champs-shared'
import { useProcessGroupApplication } from '../../group/hooks/useApproveDenyGroupApplication'
import { GroupMembership, MembershipStatusCode } from '../../group/interfaces'
import { useHistory } from 'react-router'

const breakPoint = 1024

const useStyles = makeStyles(theme => ({
  back: {
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    color: '#0F5DB5',
    textDecoration: 'none',
    display: 'block',
    clear: 'both',
    marginLeft: '-20px',
    marginBottom: '20px',
    '& > img': {
      position: 'relative',
      top: '3px',
      left: '-2px',
    },
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: '1100px',
    alignItems: 'start',
    gap: '50px',
    [theme.breakpoints.down(960)]: {
      flexDirection: 'column',
    },
  },
  content: {
    width: 'calc(100% - 427px)',
    [theme.breakpoints.down(960)]: {
      width: '427px',
    },
  },
  applicationButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  block: {
    gap: '30px',
    display: 'flex',
    flexDirection: 'column',
  },
  deny: {
    color: '#BA1B1B',
    borderColor: '#BA1B1B',
  },
}))

interface ProfileViewProps {
  match: {
    params: {
      id: number
      group?: Group
    }
  }
}

interface ProfilePageProps {
  match: {
    params: {
      id: number
      groupId?: number
    }
  }
}

export const ProfileViewComponent = ({
  match: {
    params: { id, group },
  },
}: ProfileViewProps) => {
  const history = useHistory()
  const [membershipRequests, setMembershipRequests] = useState<GroupMembership[]>([])
  useMembers(group?.id, (requests: GroupMembership[]) =>
    setMembershipRequests(
      requests.filter(request => request.person.id != id && request.status.code === MembershipStatusCode.Applicant),
    ),
  )
  const processGroupApplication = useProcessGroupApplication()
  const proceedToNextApplication = useCallback(() => {
    if (membershipRequests.length) {
      history.push(`/groups/${(group as Group).id}/applications/${membershipRequests[0].person.id}`)
    } else {
      history.push(`/groups/${(group as Group).id}`)
    }
  }, [group, history, membershipRequests])
  const approve = useCallback(async () => {
    await processGroupApplication({
      groupId: (group as Group).id,
      personIds: id,
      approve: true,
    })
    proceedToNextApplication()
  }, [membershipRequests.length]) // eslint-disable-line react-hooks/exhaustive-deps
  const deny = useCallback(async () => {
    await processGroupApplication({
      groupId: (group as Group).id,
      personIds: id,
      approve: false,
    })
    proceedToNextApplication()
  }, [membershipRequests.length]) // eslint-disable-line react-hooks/exhaustive-deps
  const [profile, setProfile] = useState<ChampionProfile | null>(null)
  const currentOrg = useCurrentOrg()
  const { loading, getProfile } = useProfile({
    id,
    onSuccess: setProfile,
    organizationId: currentOrg.id,
    includeTags: true,
    includeGroups: true,
  })
  const classes = useStyles()
  const isSmall = useBreakPoint(breakPoint)

  if (loading) {
    return <Loading />
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.block}>
        {isSmall && (
          <div className={classes.applicationButtons}>
            <OutlinedButton onClick={deny} className={classes.deny}>
              Deny
            </OutlinedButton>
            <ContainedButton onClick={approve}>Approve</ContainedButton>
          </div>
        )}
        <ProfileInfoCard
          profile={profile as ChampionProfile}
          getProfile={getProfile}
          isGroupApplicationView={!isNil(group)}
        />
      </div>

      <div className={classes.content}>
        <div className={classes.block}>
          {!isSmall && (
            <div className={classes.applicationButtons}>
              <OutlinedButton onClick={deny} className={classes.deny}>
                Deny
              </OutlinedButton>
              <ContainedButton onClick={approve}>Approve</ContainedButton>
            </div>
          )}
          {isSmall ? (
            <ProfileAccordion profile={profile as ChampionProfile} isGroupApplicationView={!isNil(group)} />
          ) : (
            <ProfileTabs profile={profile as ChampionProfile} isGroupApplicationView={!isNil(group)} />
          )}
        </div>
      </div>
    </div>
  )
}

export const ProfileView = ({
  match: {
    params: { id, groupId },
  },
}: ProfilePageProps) => {
  const { loading, group } = useGroup(groupId)
  if (loading && groupId) {
    return <Loading />
  }
  let title: string, subTitle: string, backLink: string
  if (group) {
    title = `${group.name} Application`
    subTitle = 'Back To Groups'
    backLink = '/groups'
  } else {
    title = 'Profile'
    subTitle = 'Back To Champions'
    backLink = '/champions'
  }

  return (
    <PageHeader title={title} subTitle={subTitle} backLink={backLink}>
      <ProfileViewComponent
        match={{
          params: { id, group },
        }}
      />
    </PageHeader>
  )
}
