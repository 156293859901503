import { useCallback, useEffect } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'

export const useGroup = (groupId, onResultUpdate) => {
  const [request, { result, loading, called }] = useFetch(
    'Error Fetching Group.  Please refresh the page and try again',
  )
  const currentOrg = useCurrentOrg()
  const refresh = useCallback(async () => {
    return request({
      onSuccess: result => {
        onResultUpdate && onResultUpdate(result)
      },
      method: 'get',
      url: `/organizations/${currentOrg.id}/groups/${groupId}`,
    })
  }, [currentOrg.id, groupId, onResultUpdate, request])
  useEffect(() => {
    if (groupId) {
      refresh()
    }
  }, [groupId, refresh])

  return {
    group: result,
    loading: loading || !called,
    refresh,
  }
}
