import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { ProfileOverview } from 'champion/components/ProfileOverview'
import { ChampionProfile } from 'champion/interfaces'
import { QuestionAnswerSetsGroupedView } from 'civic-champs-shared/question-sets/components/QuestionAnswerSetsGroupedView'
import { ProfileWaivers } from 'champion/components/ProfileWaivers'
import { ReflectionCalendar } from 'champion/components/ReflectionCalendar'
import { VolunteerReflections } from 'champion/components/VolunteerReflections'
import { useFeatureEnabled } from 'core/feature/hooks'
import { ProfileRegistrations } from 'champion/components/ProfileRegistrations'
import { useGetActivitiesWithReflections } from 'champion/hooks/useGetActivitiesWithReflections'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  tabs: {
    background: 'transparent !important',
    minHeight: 'auto',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: 0,
    gap: '50px',
    borderBottom: '1px solid #C4C7C7',
  },
  tab: {
    padding: 0,
    width: 'auto',
    minWidth: 'auto',
    fontFamily: 'Nunito, sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: '#8E9192',
    minHeight: 'auto',
    textTransform: 'none',
  },
  tabSelected: {
    fontWeight: 700,
    color: 'black',
  },
}))

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ marginTop: '15px' }}
    >
      {value === index && children}
    </div>
  )
}

const getTabProps = (index: number | string) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export const ProfileTabs = ({
  profile,
  isGroupApplicationView,
}: {
  profile: ChampionProfile
  isGroupApplicationView?: boolean
}) => {
  const classes = useStyles()
  const volunteerCalendarEnabled = useFeatureEnabled('VolunteerReflectionCalendar')
  const [activeTab, setActiveTab] = React.useState(0)
  const activitiesWithReflection = useGetActivitiesWithReflections(profile.activities)

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue)
  }

  const tabs = useMemo(
    () =>
      isGroupApplicationView
        ? ['Questionnaires', 'Waivers']
        : ['Overview', 'Waivers', 'Reflections', 'Questionnaires', 'Registrations'],
    [isGroupApplicationView],
  )
  let i = 0

  return (
    <div className={classes.root}>
      <Tabs
        classes={{ root: classes.tabs, flexContainer: classes.flexContainer }}
        value={activeTab}
        onChange={handleChange}
        aria-label="Volunteer Profile Tabs"
      >
        {tabs.map((label, index) => (
          <Tab
            classes={{ root: classes.tab, selected: classes.tabSelected }}
            key={label}
            label={label}
            {...getTabProps(index)}
          />
        ))}
      </Tabs>
      {!isGroupApplicationView && (
        <TabPanel index={i++} value={activeTab}>
          <ProfileOverview
            personId={profile.user.id}
            activities={profile.activities}
            donations={profile.volunteer.donation.totalDonations}
          />
        </TabPanel>
      )}
      {isGroupApplicationView && (
        <TabPanel index={i++} value={activeTab}>
          <QuestionAnswerSetsGroupedView questionSets={profile.questionSets} defaultExpanded={true} />
        </TabPanel>
      )}
      <TabPanel index={i++} value={activeTab}>
        <ProfileWaivers credentials={profile.credentials} />
      </TabPanel>
      {!isGroupApplicationView && (
        <TabPanel index={i++} value={activeTab}>
          {volunteerCalendarEnabled ? (
            <ReflectionCalendar activities={activitiesWithReflection} />
          ) : (
            <VolunteerReflections activities={activitiesWithReflection} />
          )}
        </TabPanel>
      )}
      {!isGroupApplicationView && (
        <TabPanel index={i++} value={activeTab}>
          <QuestionAnswerSetsGroupedView questionSets={profile.questionSets} />
        </TabPanel>
      )}
      {!isGroupApplicationView && (
        <TabPanel index={i++} value={activeTab}>
          <ProfileRegistrations personId={profile.user.id} />
        </TabPanel>
      )}
    </div>
  )
}
