import { RouteComponentProps } from 'react-router'
import PageHeader from '../../App/components/PageHeader'
import React, { useCallback, useMemo, useState } from 'react'
import useCredential from '../hooks/useCredential'
import Loading from '../../components/Loading'
import ContainedButton from '../../civic-champs-shared/core/ContainedButton'
import EditIcon from '@material-ui/icons/Edit'
import OutlinedButton from '../../civic-champs-shared/core/OutlinedButton'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { Field, Form, Formik } from 'formik'
import { CredentialContentType, CredentialFull } from '../../civic-champs-shared/question-sets/types'
import { StyledInput } from '../../civic-champs-shared/formik/components/StyledInput'
import RadioGroupFormField from '../../components/RadioGroupFormField'
import AdvancedAutocomplete from '../../civic-champs-shared/core/AdvancedAutocomplete'
import find from 'lodash/find'
import { StyledKeyboardDatePicker } from '../../civic-champs-shared/formik/components/StyledFormikDateTimePickers'
import dayjs from '../../utils/dayjs'
import { useWaiverAddEditStyles } from '../hooks/useWaiverAddEditStyles'
import {
  contentTypeOptions,
  credentialExpirationOptions,
  defaultWaiverText,
  statementTypeOptions,
  validationSchema,
} from 'credential/helpers'
import { useCurrentOrg } from '../../civic-champs-shared/auth/hooks'
import { useDateTimeUtils } from '../../civic-champs-shared/utils/useDateTimeUtils'
import { CredentialExpirationOption, WaiverFormValues } from '../models'
import useCreateOrUpdateCredential from '../hooks/useCreateOrUpdateCredential'
import useWaiverPreviewPrompt from '../hooks/useWaiverPreviewPrompt'
import { useBroadcastChannel } from '../../civic-champs-shared/core/hooks/useBroadcastChannel'
import { CkEditorStyledField } from '../../civic-champs-shared/formik/components/CkEditorStyledField'
import { CheckboxWithLabel } from 'formik-material-ui'
import cn from 'classnames'

export const WaiversComponent = (props: RouteComponentProps<{ id?: string }>) => {
  const channelMatch = window.location.hash.match(/channel=([^&]+)/)
  const channel = channelMatch ? channelMatch[1] : null
  const { postMessage } = useBroadcastChannel(channel)
  const id = useMemo(
    () => (props.match.params.id ? parseInt(props.match.params.id) : undefined),
    [props.match.params.id],
  )
  const organization = useCurrentOrg()
  const { fakeAsLocalTimeForUI, unfakeAsLocalTimeForUIAsString } = useDateTimeUtils()
  const saveWaiver = useCreateOrUpdateCredential()
  const handlePreview = useWaiverPreviewPrompt()

  const { result, loading } = useCredential(id)
  const [previewEnabled, setPreviewEnabled] = useState(false)
  const classes = useWaiverAddEditStyles()
  const {
    title = '',
    description = '',
    content = '',
    contentType = CredentialContentType.Text,
    url = '',
    waiverStatement = defaultWaiverText,
  } = result?.version || {}
  const {
    expirationOption = CredentialExpirationOption.Month,
    expirationOptionLength = 1,
    expirationDate,
    isRequired = true,
  } = result?.credential || {}

  const backText = useMemo(() => {
    return channel ? 'Back to Event' : id ? `Back to ${title}` : 'Back to Waivers'
  }, [channel, id, title])

  const initialValues = useMemo(
    () =>
      ({
        isPublished: true,
        type: 'waiver',
        organizationId: organization.id,
        title,
        description,
        content,
        contentType,
        url,
        waiverStatement,
        expirationOption,
        expirationOptionLength,
        expirationDate: expirationDate
          ? fakeAsLocalTimeForUI(expirationDate).toDate()
          : dayjs().set('month', 11).set('date', 31).set('hour', 0).set('minute', 0).set('second', 0).toDate(),
        statementType: id ? 'custom' : 'default',
        isRequired,
      } as WaiverFormValues),
    [result], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleComplete = useCallback(
    ({ id, result }: { id?: number; result?: CredentialFull }) => {
      if (channel) {
        if (result) {
          postMessage(result)
        }
        window.close()
      } else {
        props.history.push('/credentials' + (id ? `/${id}` : ''))
      }
    },
    [channel, postMessage, props.history],
  )

  const handleBackClick = useCallback(() => {
    handleComplete({ id })
  }, [handleComplete, id])

  const handleSubmit = useCallback(
    async ({ statementType, expirationDate, ...values }: WaiverFormValues) => {
      if (expirationDate < new Date()) {
        expirationDate = dayjs(expirationDate).add(1, 'year').toDate()
      }
      const waiver = await saveWaiver({ ...values, expirationDate: unfakeAsLocalTimeForUIAsString(expirationDate) }, id)
      handleComplete({ id: waiver.id, result: waiver })
    },
    [handleComplete, id, saveWaiver, unfakeAsLocalTimeForUIAsString],
  )

  if (loading) {
    return <Loading />
  }

  return (
    <div className={classes.wrapper}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ values, isValid, isSubmitting, setFieldValue, touched, errors }) => {
          if (values.statementType === 'default' && values.waiverStatement !== defaultWaiverText) {
            setFieldValue('waiverStatement', defaultWaiverText)
          }
          return (
            <Form>
              <div className={classes.topBlock}>
                <a href="#" className={classes.backLink} onClick={handleBackClick}>
                  ← {backText}
                </a>
                <div className={classes.buttons}>
                  {previewEnabled ? (
                    <ContainedButton startIcon={<EditIcon />} onClick={() => setPreviewEnabled(false)}>
                      Edit
                    </ContainedButton>
                  ) : (
                    <OutlinedButton startIcon={<VisibilityIcon />} onClick={() => handlePreview(values)}>
                      Preview
                    </OutlinedButton>
                  )}
                  <ContainedButton disabled={!isValid || isSubmitting} type="submit">
                    Save
                  </ContainedButton>
                </div>
              </div>
              <div className={classes.form}>
                <p className={classes.title}>Waiver Content:</p>
                <Field
                  name="title"
                  component={StyledInput}
                  label="Waiver Title*"
                  placeholder="Write the waiver’s name here..."
                />
                <div className={classes.checkboxSection}>
                  <p className={classes.subTitle}>Waiver Status:</p>
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    color="primary"
                    className={classes.checkbox}
                    name="isRequired"
                    Label={{ label: 'Required' }}
                  />
                </div>
                <div className={classes.section}>
                  <p className={classes.subTitle}>Waiver Format:</p>
                  <RadioGroupFormField name="contentType" disabled={isSubmitting} options={[contentTypeOptions[0]]} />
                  {values.contentType === CredentialContentType.Text && (
                    <div className={classes.dependent}>
                      <div className={classes.textarea}>
                        <Field
                          id="content"
                          name="content"
                          label="Waiver Body"
                          placeholder="Write the legal content of the waiver to be signed by volunteers..."
                          component={CkEditorStyledField}
                        />
                      </div>
                    </div>
                  )}
                  <RadioGroupFormField name="contentType" disabled={isSubmitting} options={[contentTypeOptions[1]]} />
                  {values.contentType === CredentialContentType.Url && (
                    <div className={classes.dependent}>
                      <Field
                        name="url"
                        component={StyledInput}
                        label="Waiver URL"
                        placeholder="Enter the URL to the linked waiver here..."
                      />
                    </div>
                  )}
                </div>
                <div className={classes.section}>
                  <p className={classes.subTitle}>Waiver Agreement Statement:</p>
                  <RadioGroupFormField
                    name="statementType"
                    disabled={isSubmitting}
                    options={[statementTypeOptions[0]]}
                  />
                  {values.statementType === 'default' && (
                    <div className={cn(classes.dependent, classes.defaultText)}>
                      {defaultWaiverText.substring(3, defaultWaiverText.length - 7)}
                    </div>
                  )}
                  <RadioGroupFormField
                    name="statementType"
                    disabled={isSubmitting}
                    options={[statementTypeOptions[1]]}
                  />
                  {values.statementType === 'custom' && (
                    <div className={classes.dependent}>
                      <Field
                        id="waiverStatement"
                        name="waiverStatement"
                        label="Waiver Agreement"
                        disabled={isSubmitting}
                        component={CkEditorStyledField}
                      />
                    </div>
                  )}
                </div>
                <div className={classes.textarea}></div>
                <div className={classes.section}>
                  <p className={classes.subTitle}>Expiration Details:</p>
                  <div className={classes.expirationContainer}>
                    <div className={classes.expiration}>
                      <AdvancedAutocomplete<{ label: string; value: CredentialExpirationOption }>
                        label={'Expiration*'}
                        placeholder={'Select an Email field'}
                        value={find(credentialExpirationOptions, { value: values.expirationOption })}
                        disableInputFilter
                        options={credentialExpirationOptions}
                        getOptionInfo={option => ({ left: option.label, id: option.value })}
                        onChange={value => setFieldValue('expirationOption', (value as any).value)}
                        error={touched.expirationOption ? errors.expirationOption : undefined}
                        clearable={false}
                      />
                    </div>
                    {![CredentialExpirationOption.DayOf, CredentialExpirationOption.Updated].includes(
                      values.expirationOption,
                    ) && (
                      <div className={classes.expiration}>
                        {values.expirationOption === CredentialExpirationOption.Date ? (
                          <Field
                            name="expirationDate"
                            higher
                            component={StyledKeyboardDatePicker}
                            format="MM/DD"
                            keyboardIcon={null}
                            inputVariant="outlined"
                          />
                        ) : (
                          <Field
                            name="expirationOptionLength"
                            component={StyledInput}
                            placeholder={`Enter the number of ${values.expirationOption}s...`}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className={classes.section}>
                  <p className={classes.subTitle}>Administrator’s Notes:</p>
                  <div className={classes.textarea}>
                    <Field
                      id="description"
                      name="description"
                      label="Waiver Notes"
                      placeholder="Write additional notes for administrators (volunteers will not see this)"
                      component={CkEditorStyledField}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export const WaiverAddEdit = (props: RouteComponentProps<{ id: string }>) => {
  const title = props.match.params.id ? 'Edit Waiver' : 'Add Waiver'
  return (
    <PageHeader title={title}>
      <WaiversComponent {...props} />
    </PageHeader>
  )
}

export default WaiverAddEdit
