import React from 'react'
import { Autocomplete } from 'formik-material-ui-lab'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { makeStyles, TextField } from '@material-ui/core'
import { getBasicTextFieldStyles } from 'civic-champs-shared/core/add-person/PersonAutocomplete'
import isUndefined from 'lodash/isUndefined'

const useStyles = makeStyles({
  root: ({ legendWidthFixture }: any) => getBasicTextFieldStyles(legendWidthFixture),
  input: {
    height: '31px',
  },
})

export const StyledAutocomplete = ({ label, placeholder, setOpen, notched, variant, labelTopShift, ...props }: any) => {
  const {
    field: { name },
    form: { touched, errors },
  } = props
  const classes = useStyles({ dark: variant === 'form', labelTopShift })
  return (
    <Autocomplete
      {...props}
      style={{ width: '100%' }}
      popupIcon={<KeyboardArrowDownIcon onClick={() => setOpen && setOpen((v: boolean) => !v)} />}
      renderInput={(params: any) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            notched,
            classes: {
              input: variant === 'form' ? classes.input : undefined,
            },
          }}
          InputLabelProps={{
            ...params.InputLabelProps,
            shrink: notched,
          }}
          fullWidth
          classes={classes}
          label={label}
          placeholder={isUndefined(placeholder) ? label : placeholder}
          variant="outlined"
          error={Boolean(touched[name] && errors[name])}
          helperText={touched[name] && errors[name]}
        />
      )}
    />
  )
}
