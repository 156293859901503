import React from 'react'
import { ChampionProfile } from 'champion/interfaces'
import { ExpansionPanel, ExpansionPanelDetails, Typography } from '@material-ui/core'
import { ProfileOverview } from 'champion/components/ProfileOverview'
import { ProfileWaivers } from 'champion/components/ProfileWaivers'
import { VolunteerReflections } from 'champion/components/VolunteerReflections'
import { QuestionAnswerSetsGroupedView } from 'civic-champs-shared/question-sets/components/QuestionAnswerSetsGroupedView'
import { ReflectionCalendar } from 'champion/components/ReflectionCalendar'
import { useFeatureEnabled } from 'core/feature/hooks'
import { ProfileRegistrations } from 'champion/components/ProfileRegistrations'
import { useProfileAccordionStyles } from 'champion/hooks/useProfileAccordionStyles'
import { ProfileExtensionPanelSummary } from 'champion/components/ProfileExtensionPanelSummary'
import { useGetActivitiesWithReflections } from 'champion/hooks/useGetActivitiesWithReflections'

export const ProfileAccordion = ({
  profile,
  isGroupApplicationView,
}: {
  profile: ChampionProfile
  isGroupApplicationView?: boolean
}) => {
  const classes = useProfileAccordionStyles()
  const volunteerCalendarEnabled = useFeatureEnabled('VolunteerReflectionCalendar')
  const activitiesWithReflection = useGetActivitiesWithReflections(profile.activities)
  let i = 0
  return (
    <div>
      {!isGroupApplicationView && (
        <ExpansionPanel
          classes={{ root: classes.panelRoot }}
          defaultExpanded={i === 0}
          TransitionProps={{ unmountOnExit: true }}
        >
          <ProfileExtensionPanelSummary aria-controls={`${i}-content`} id={`${i++}-header`}>
            <Typography className={classes.header}>Overview</Typography>
          </ProfileExtensionPanelSummary>
          <ExpansionPanelDetails classes={{ root: classes.root }}>
            <ProfileOverview
              personId={profile.user.id}
              activities={profile.activities}
              donations={profile.volunteer.donation.totalDonations}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}

      {isGroupApplicationView && (
        <ExpansionPanel
          classes={{ root: classes.panelRoot }}
          TransitionProps={{ unmountOnExit: true }}
          defaultExpanded={i === 0}
        >
          <ProfileExtensionPanelSummary aria-controls={`${i}-content`} id={`${i++}-header`}>
            <Typography className={classes.header}>Questionnaires</Typography>
          </ProfileExtensionPanelSummary>
          <ExpansionPanelDetails classes={{ root: classes.root }}>
            <QuestionAnswerSetsGroupedView questionSets={profile.questionSets} defaultExpanded={true} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      <ExpansionPanel classes={{ root: classes.panelRoot }} TransitionProps={{ unmountOnExit: true }}>
        <ProfileExtensionPanelSummary aria-controls={`${i}-content`} id={`${i++}-header`}>
          <Typography className={classes.header}>Waivers</Typography>
        </ProfileExtensionPanelSummary>
        <ExpansionPanelDetails classes={{ root: classes.root }}>
          <ProfileWaivers credentials={profile.credentials} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      {!isGroupApplicationView && (
        <ExpansionPanel classes={{ root: classes.panelRoot }} TransitionProps={{ unmountOnExit: true }}>
          <ProfileExtensionPanelSummary aria-controls={`${i}-content`} id={`${i++}-header`}>
            <Typography className={classes.header}>Reflections</Typography>
          </ProfileExtensionPanelSummary>
          <ExpansionPanelDetails classes={{ root: classes.root }}>
            {volunteerCalendarEnabled ? (
              <ReflectionCalendar activities={activitiesWithReflection} />
            ) : (
              <VolunteerReflections activities={activitiesWithReflection} />
            )}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      {!isGroupApplicationView && (
        <ExpansionPanel classes={{ root: classes.panelRoot }} TransitionProps={{ unmountOnExit: true }}>
          <ProfileExtensionPanelSummary aria-controls={`${i}-content`} id={`${i++}-header`}>
            <Typography className={classes.header}>Questionnaires</Typography>
          </ProfileExtensionPanelSummary>
          <ExpansionPanelDetails classes={{ root: classes.root }}>
            <QuestionAnswerSetsGroupedView questionSets={profile.questionSets} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      {!isGroupApplicationView && (
        <ExpansionPanel classes={{ root: classes.panelRoot }} TransitionProps={{ unmountOnExit: true }}>
          <ProfileExtensionPanelSummary aria-controls={`${i}-content`} id={`${i++}-header`}>
            <Typography className={classes.header}>Registrations</Typography>
          </ProfileExtensionPanelSummary>
          <ExpansionPanelDetails classes={{ root: classes.root }}>
            <ProfileRegistrations personId={profile.user.id} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
    </div>
  )
}
