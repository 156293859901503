import React from 'react'
import format from 'civic-champs-shared/utils/format'
import { useCurrentDateWithFilename } from 'components/table/ExportCsvMenuItem'
import { ExportButton } from 'core/table/components'
import { Row } from 'react-table'
import { DailyEventTableRow } from 'Event/components/DailyEventsTable'

const filename = 'daily-events.csv'
const headers = [
  { label: 'Event Name', key: 'name' },
  { label: 'Event Start', key: 'startsAt' },
  { label: 'Event End', key: 'endsAt' },
  { label: 'Registered Volunteers', key: 'registrations.confirmed' },
  { label: 'Total Required Volunteers', key: 'registrations.total' },
]

const formatData = (events: Row<DailyEventTableRow>[]) => {
  return events.map(({ original: { startsAt, endsAt, registrations, name } }) => {
    return {
      name,
      startsAt: format.datetimeForExport(startsAt),
      endsAt: format.datetimeForExport(endsAt),
      registrations,
    }
  })
}
const ExportDailyEventsButton = ({ data, disabled }: { data: any[]; disabled?: boolean }) => {
  const fileNameWithDate = useCurrentDateWithFilename(filename)

  return (
    <ExportButton
      data={data}
      formatData={formatData}
      headers={headers}
      disabled={disabled}
      filename={fileNameWithDate as string}
      type="popover"
    />
  )
}

export default ExportDailyEventsButton
