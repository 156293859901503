import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import cn from 'classnames'
import find from 'lodash/find'

import {
  CheckCircle as CheckCircleIcon,
  Close as CloseIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons'
import { ThemeProvider } from '@material-ui/core/styles'
import { Fade, Popper } from '@material-ui/core'

import { useHandleBlur } from 'civic-champs-shared/helpers/useHandleBlur'
import { useHandleScroll } from 'civic-champs-shared/helpers/useHandleScroll'
import { Person, PersonRef } from 'civic-champs-shared/common/types'

import { muiTheme } from 'theme'

import { useDisplayAreaInfo } from 'messages/hooks/useDisplayAreaInfo'
import PersonEmbeddedAutocomplete, { GetContent } from 'messages/components/PersonEmbeddedAutocomplete'
import { GetFirstLastId, useRecipientsPlaceholderText } from 'messages/hooks/useRecipientsPlaceholderText'
import { HALF_HEIGHT, ROW_HEIGHT, useVolunteerRowStyles, useVolunteerSelectorStyles } from '../hooks'
import { useFetchVolunteers } from 'volunteering/volunteers/hooks/useFetchVolunteers'

interface VolunteerSelectorProps {
  volunteers: (Person | PersonRef)[]
  onChange: (value: (Person | PersonRef)[]) => void
  existingVolunteers?: Person[]
  alternative?: boolean
  label?: string
  placeholder?: string
  maxWidth?: number
  maxLength?: number
}

const getFirstLastId: GetFirstLastId<Person | PersonRef> = ({ id, givenName, familyName }: Person | PersonRef) => ({
  id,
  firstName: givenName || '',
  lastName: familyName || '',
})

const getContent: GetContent<PersonRef> = ({ id, givenName, familyName, email, phoneNumber }: PersonRef) => ({
  id,
  left: `${givenName} ${familyName}`,
  right: email || phoneNumber,
})

const VolunteerRow = ({ volunteer, onDelete }: { volunteer: Person | PersonRef; onDelete: (id: number) => void }) => {
  const classes = useVolunteerRowStyles()
  const { email, phoneNumber } = volunteer
  return (
    <div className={classes.recipientRow}>
      <div className={classes.recipientContainer}>
        <CheckCircleIcon className={classes.rowIcon} />{' '}
        <div className={classes.recipientNameContainer}>
          <div>
            {volunteer.givenName} {volunteer.familyName}
          </div>
          <div className={classes.email}>{email || phoneNumber}</div>
        </div>
      </div>
      <CloseIcon className={classes.closeIcon} onClick={() => onDelete(volunteer.id)} />
    </div>
  )
}

const VolunteerSelector = ({
  volunteers,
  onChange,
  existingVolunteers = [],
  alternative = false,
  label,
  placeholder = 'Select Volunteers *',
  maxWidth,
  maxLength,
}: VolunteerSelectorProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [scrollTopPosition, setScrollTopPosition] = useState(0)
  const [contacts, setContacts] = useState<(Person | PersonRef)[]>([])
  const { text, more } = useRecipientsPlaceholderText<Person | PersonRef>({
    contacts: volunteers,
    getFirstLastId,
    maxLength: alternative ? 40 : maxLength,
    maxWidth: alternative ? 440 : maxWidth,
  })
  const [textDivWidth, setTextDivWidth] = useState<number>(0)
  const anchorEl = useRef<HTMLDivElement | null>(null)
  const focusEl = useRef<HTMLDivElement | null>(null)
  const { fetchVolunteers } = useFetchVolunteers()

  useEffect(() => {
    const fetch = async () => {
      const result = await fetchVolunteers({ reporting: false })
      setContacts(result.map(({ user }) => user as Person))
    }
    fetch()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const filteredContacts = useMemo(
    () =>
      contacts.filter(
        (user: PersonRef) =>
          !volunteers.find(volunteer => volunteer.id === user.id) &&
          !existingVolunteers.find(volunteer => volunteer.id === user.id),
      ),
    [contacts, volunteers, existingVolunteers],
  )
  const classes = useVolunteerSelectorStyles({ alternative, noMargin: true, textDivWidth })
  const { topPlaceholderHeight, bottomPlaceholderHeight, visibleContacts } = useDisplayAreaInfo<Person | PersonRef>({
    contacts: volunteers,
    scrollTopPosition,
    rowHeight: ROW_HEIGHT,
    height: HALF_HEIGHT,
  })

  const setAnchorEl = useCallback((element: HTMLDivElement | null) => {
    anchorEl.current = element
    if (element) {
      setTextDivWidth(element.getBoundingClientRect().width)
    }
  }, [])

  const textDiv = useMemo(
    () => (
      <div className={cn(classes.text, { [classes.textPlaceholder]: !text && placeholder })}>
        {volunteers.length ? (
          <>
            {text}
            {more ? (
              <span>
                {'\u00A0'}+{more} more
              </span>
            ) : null}
          </>
        ) : (
          placeholder
        )}
      </div>
    ),
    [classes.text, classes.textPlaceholder, text, placeholder, volunteers.length, more],
  )
  const handleClick = () => {
    setScrollTopPosition(0)
    setOpen(val => !val)
  }

  const handleAdd = (id: number) => {
    const person = find(contacts, { id }) as PersonRef
    onChange([...volunteers, person])
  }

  const handleScroll = useHandleScroll(setScrollTopPosition)

  const handleBlur = useHandleBlur(focusEl, () => setOpen(false))

  const handleDelete = useCallback(
    (id: number) => {
      onChange(volunteers.filter(volunteer => volunteer.id !== id))
    },
    [onChange, volunteers],
  )

  return (
    <>
      <div
        id="volunteer-selector"
        className={cn(classes.input, { [classes.open]: open })}
        tabIndex={-1}
        onClick={handleClick}
        ref={setAnchorEl}
      >
        {label && <div className={classes.label}>{label}</div>}
        {textDiv}
        {open ? (
          <ExpandLessIcon className={classes.expandCollapse} />
        ) : (
          <ExpandMoreIcon className={classes.expandCollapse} />
        )}
      </div>
      <Popper
        className={classes.popper}
        id="volunteer-selector-popper"
        open={open}
        anchorEl={anchorEl.current}
        transition
        tabIndex={-1}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div tabIndex={-1} className={classes.popperPaper} ref={focusEl} onBlur={handleBlur}>
              <div className={classes.selected}>
                <strong>Selected</strong>
              </div>
              <div className={classes.recipients} onScroll={handleScroll}>
                <div className={classes.placeholder} style={{ height: topPlaceholderHeight }} />
                {visibleContacts.map(volunteer => (
                  <VolunteerRow key={volunteer.id} volunteer={volunteer} onDelete={handleDelete} />
                ))}
                <div className={classes.placeholder} style={{ height: bottomPlaceholderHeight }} />
              </div>
              <div style={{ borderTop: '1px rgba(0,0,0,0.1) solid', margin: '5px 9.5px' }} />
              <PersonEmbeddedAutocomplete<PersonRef>
                onAdd={handleAdd}
                selectedContactCount={volunteers.length}
                contacts={filteredContacts}
                getFirstLastId={getFirstLastId}
                getContent={getContent}
              />
            </div>
          </Fade>
        )}
      </Popper>
    </>
  )
}

export default (props: VolunteerSelectorProps) => (
  <ThemeProvider theme={muiTheme}>
    <VolunteerSelector {...props} />
  </ThemeProvider>
)
