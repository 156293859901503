import { OpportunityResponse } from 'Event/interfaces/interfaceCreateEditEvent'
import { DailyEventTableRow, OpportunityRegistrations } from 'Event/components/DailyEventsTable'
import { groupBy } from 'lodash'
import moment from 'moment'
import sortBy from 'lodash/sortBy'
import minBy from 'lodash/minBy'
import maxBy from 'lodash/maxBy'
import { RegistrationResult, Shift } from '../interfaces'
import dayjs from 'utils/dayjs'
import { encodeOccurrence } from './encodeOccurrence'
import get from 'lodash/get'

const emptyRegistrations: OpportunityRegistrations = {
  total: 0,
  confirmed: 0,
}

const mapOpportunityResponseToRow = ({
  id,
  occurrenceId,
  opportunityId,
  startsAt,
  endsAt,
  name,
  timeshifts,
  organization,
}: OpportunityResponse): DailyEventTableRow => ({
  id,
  occurrenceId,
  opportunityId,
  startsAt: new Date(startsAt),
  endsAt: endsAt ? new Date(endsAt) : undefined,
  name: name,
  organization,
  registrations: (timeshifts || []).reduce((registrations: OpportunityRegistrations, shift) => {
    return shift.roles.reduce(
      (registrations, role) => ({
        total: registrations.total + role.all,
        confirmed: registrations.confirmed + role.all - role.available,
      }),
      registrations,
    )
  }, emptyRegistrations),
})

const getShiftDate = (eventDate: string | Date, shiftTime: string, timeZone?: string): Date => {
  timeZone = timeZone || dayjs.tz.guess()
  const eventStringDate = dayjs.tz(eventDate, timeZone).format('YYYY-MM-DD')
  return dayjs.tz(`${eventStringDate} ${shiftTime}`, 'YYYY-MM-DD LT', timeZone).toDate()
}

const mapOpportunityResponseToRowByTimeshiftAndRole = (
  props: OpportunityResponse,
): DailyEventTableRow | DailyEventTableRow[] => {
  if (!(props.timeshifts || []).length) {
    return mapOpportunityResponseToRow(props)
  }
  const { id, occurrenceId, opportunityId, startsAt, endsAt, name, timeshifts, organization } = props
  return (timeshifts as Shift[]).flatMap((shift: Shift) =>
    shift.roles.map(role => {
      return {
        id,
        occurrenceId,
        opportunityId,
        startsAt: getShiftDate(startsAt, shift.time_start, organization.timeZone),
        endsAt: getShiftDate(startsAt, shift.time_end, organization.timeZone),
        eventStartsAt: startsAt,
        name: name,
        organization,
        roleId: role.id,
        roleName: role.name,
        shiftName: shift.name,
        shiftId: shift.id,
        registrations: {
          total: role.all,
          confirmed: role.all - role.available,
        },
      }
    }),
  )
}

export const mapDailyEvents = (
  events: OpportunityResponse[],
  splitEventsByShiftAndRole = false,
): DailyEventTableRow[] => {
  const rows = events.flatMap(
    splitEventsByShiftAndRole ? mapOpportunityResponseToRowByTimeshiftAndRole : mapOpportunityResponseToRow,
  )
  const groupedRows = groupBy(rows, ({ startsAt }) => moment(startsAt).format('YYYY-MM-DD'))
  return sortBy(
    Object.entries(groupedRows).map(([date, rows]) => ({
      id: parseInt(date.replace('-', '')),
      name: moment(date).format('dddd, MMMM Do'),
      startsAt: (minBy(rows, 'startsAt') as DailyEventTableRow).startsAt,
      endsAt: (maxBy(rows, 'endsAt') as DailyEventTableRow).endsAt,
      registrations: rows.reduce(
        (registrations, row) => ({
          total: registrations.total + row.registrations.total,
          confirmed: registrations.confirmed + row.registrations.confirmed,
        }),
        emptyRegistrations,
      ),
      subRows: sortBy(rows, 'startsAt'),
    })),
    'startsAt',
  )
}

export const encodeOccurrenceFromDailyEventTableRow = (row: DailyEventTableRow): string => {
  const timeZone = get(row, 'organization.timeZone', moment.tz.guess(true))
  const dateString = moment.tz(row.eventStartsAt, timeZone).format('YYYY-MM-DD')
  return encodeOccurrence(row.opportunityId as number, dateString)
}

export const mapAdditionalRegistrations =
  (registrationResults: RegistrationResult[], bulkRegistrationEnabled: boolean) => (row: DailyEventTableRow) => {
    if (!bulkRegistrationEnabled) return row
    row.subRows?.forEach(subRow => {
      const additionalCount = registrationResults.reduce((count, result) => {
        const rowEncodedOccurrence = encodeOccurrenceFromDailyEventTableRow(subRow)
        const { roleId, encodedOccurrence, newRegistrationsCount, timeshiftId } = result
        if (rowEncodedOccurrence !== encodedOccurrence || roleId != subRow.roleId || timeshiftId != subRow.shiftId) {
          return count
        }
        return count + newRegistrationsCount
      }, 0)
      subRow.registrations.confirmed += additionalCount
      row.registrations.confirmed += additionalCount
    })

    return row
  }
