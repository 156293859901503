import { OrgNamedRef, PersonRef } from '../civic-champs-shared/common/types'

export enum MembershipStatusCode {
  Applicant = 'applicant',
  Declined = 'declined',
  ProbationaryMember = 'probationary member',
  Member = 'member',
  Removed = 'removed',
  Banned = 'banned',
  Withdrawn = 'withdrawn',
}

export interface MembershipStatus {
  id: number
  code: MembershipStatusCode
  displayName: string
}

export interface GroupMembership {
  id?: number
  groupId: number
  groupName: string
  person: PersonRef
  status: MembershipStatus
  createdAt?: Date
  startedAt?: Date
  archivedAt?: Date
  stoppedAt?: Date
  organization?: OrgNamedRef
  latestActivity?: Date
}
