import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { useCallback } from 'react'
import BulkRegistrationDialog from '../components/BulkRegistrationDialog'
import { PersonRef } from '../../civic-champs-shared/common/types'
import { RoleWithShift } from '../interfaces'
import { useBulkRegisterVolunteers } from './useBulkRegisterVolunteers'
import { useCreateApiNotification } from '../../civic-champs-shared/api/hooks'

export const useShowBulkRegistrationDialog = () => {
  const showPrompt = useShowPrompt(BulkRegistrationDialog)
  const bulkRegister = useBulkRegisterVolunteers()
  const createNotification = useCreateApiNotification()

  return useCallback(
    async (rolesWithShift: RoleWithShift[]) => {
      const volunteers: PersonRef[] = await showPrompt()
      const notification = createNotification('Registering Volunteers')
      try {
        const response = await bulkRegister(volunteers, rolesWithShift)
        notification.onSuccess(`Volunteers Registered`)
        return response
      } catch (e) {
        notification.onError(`Failed to Register Volunteers`, e)
      }
    },
    [bulkRegister, createNotification, showPrompt],
  )
}
