import React, { useCallback } from 'react'
import { Form, Formik } from 'formik'
import { Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Grid, IconButton } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import yup from 'civic-champs-shared/utils/yup'
import DraggablePaper from 'civic-champs-shared/core/confirm-dialog/DraggablePaper'
import { ContainedButton, OutlinedButton } from 'civic-champs-shared/core'
import { PersonRef } from 'civic-champs-shared/common/types'

import { muiTheme } from 'theme'
import { useAddOrEditActivityPromptStyle } from '../../tracking/activity/hooks'
import VolunteerSelector from '../../tracking/activity/components/VolunteerSelector'

interface Props {
  showing: boolean
  complete: (value: any) => void
  close: () => void
}

const validationSchema = () =>
  yup.object({
    volunteers: yup
      .array()
      .of(
        yup.object({
          // @ts-ignore
          id: yup.number().id(),
          givenName: yup.string().trim().required(),
          familyName: yup.string().trim().required(),
        }),
      )
      .min(1, 'Please select one or more volunteers.')
      .required(),
  })

export function BulkRegistrationDialog(props: Props) {
  const { showing, close, complete } = props

  const classes = useAddOrEditActivityPromptStyle()

  const handleSubmit = useCallback(
    async (values: any) => {
      complete(values.volunteers)
    },
    [complete],
  )

  const initialValues = {
    volunteers: [],
  }

  return (
    // @ts-ignore
    <Dialog
      open={showing}
      // @ts-ignore
      PaperComponent={DraggablePaper}
      // @ts-ignore
      PaperProps={{ handle: `#bulk-add-registration` }}
      aria-labelledby="draggable-dialog-title"
      maxWidth="xs"
      classes={{ paper: classes.dialog }}
      disableEnforceFocus
    >
      <Formik initialValues={initialValues} validationSchema={validationSchema()} onSubmit={handleSubmit}>
        {({ submitForm, setFieldValue, isSubmitting, values, errors }) => {
          return (
            <Form>
              <DialogTitle classes={{ root: classes.title }} disableTypography={true} id="bulk-add-registration">
                Add Registrations
                <IconButton className={classes.dialogCloseButton} onClick={() => close()}>
                  <CloseIcon className={classes.dialogCloseIcon} />
                </IconButton>
              </DialogTitle>
              <DialogContent classes={{ root: classes.content }}>
                <Grid>
                  <Grid item className={classes.rowContent}>
                    <VolunteerSelector
                      volunteers={values.volunteers}
                      onChange={(value: PersonRef[]) => setFieldValue('volunteers', value)}
                      existingVolunteers={[]}
                    />
                    <FormHelperText error={!!errors.volunteers}>{errors.volunteers}</FormHelperText>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className={classes.actions}>
                <OutlinedButton disabled={isSubmitting} onClick={close}>
                  Cancel
                </OutlinedButton>
                <ContainedButton disabled={isSubmitting} isLoading={isSubmitting} onClick={submitForm}>
                  Add
                </ContainedButton>
              </DialogActions>
            </Form>
          )
        }}
      </Formik>
    </Dialog>
  )
}

export default (props: Props) => (
  <ThemeProvider theme={muiTheme}>
    <BulkRegistrationDialog {...props} />
  </ThemeProvider>
)
