import React, { useCallback } from 'react'
import { Form, Formik } from 'formik'
import { Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Grid, IconButton } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import yup from 'civic-champs-shared/utils/yup'
import DraggablePaper from 'civic-champs-shared/core/confirm-dialog/DraggablePaper'
import { ContainedButton, OutlinedButton } from 'civic-champs-shared/core'
import { PersonRef } from 'civic-champs-shared/common/types'
import { muiTheme } from 'theme'
import map from 'lodash/map'
import VolunteerSelector from '../../tracking/activity/components/VolunteerSelector'
import { useAddOrEditActivityPromptStyle } from '../../tracking/activity/hooks'

interface AddSignupsPromptProps {
  showing: boolean
  complete: (value: any) => void
  close: () => void
}

const validationSchema = yup.object({
  volunteers: yup
    .array()
    .of(
      yup.object({
        // @ts-ignore
        id: yup.number().id(),
      }),
    )
    .min(1, 'Please select one or more volunteers.')
    .required(),
})

const initialValues = {
  volunteers: [],
}

export function AddSignupsPrompt(props: AddSignupsPromptProps) {
  const { showing, close, complete } = props

  const classes = useAddOrEditActivityPromptStyle({ forSignups: true })

  const handleSubmit = useCallback(
    async (values: { volunteers: PersonRef[] }) => {
      complete(map(values.volunteers, 'id'))
    },
    [complete],
  )

  return (
    // @ts-ignore
    <Dialog
      open={showing}
      // @ts-ignore
      PaperComponent={DraggablePaper}
      // @ts-ignore
      PaperProps={{ handle: `add-signups-prompt` }}
      aria-labelledby="draggable-dialog-title"
      maxWidth="xs"
      classes={{ paper: classes.dialog }}
      disableEnforceFocus
    >
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ submitForm, setFieldValue, setFieldTouched, isSubmitting, values, touched, errors }) => {
          return (
            <Form>
              <DialogTitle classes={{ root: classes.title }} disableTypography={true} id="add-signups-prompt">
                Add Sign-Ups
                <IconButton className={classes.dialogCloseButton} onClick={() => close()}>
                  <CloseIcon className={classes.dialogCloseIcon} />
                </IconButton>
              </DialogTitle>
              <DialogContent classes={{ root: classes.content }}>
                <Grid>
                  <Grid item className={classes.rowContent}>
                    <>
                      <VolunteerSelector
                        volunteers={values.volunteers}
                        onChange={(value: PersonRef[]) => setFieldValue('volunteers', value)}
                        existingVolunteers={[]}
                        maxWidth={300}
                        maxLength={35}
                        label={values.volunteers.length ? 'Volunteer*' : undefined}
                        placeholder="Select Volunteer(s)"
                      />
                      {!!errors.volunteers && (
                        <FormHelperText error={!!errors.volunteers}>{errors.volunteers}</FormHelperText>
                      )}
                    </>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className={classes.actions}>
                <OutlinedButton disabled={isSubmitting} onClick={close}>
                  Cancel
                </OutlinedButton>
                <ContainedButton disabled={isSubmitting} isLoading={isSubmitting} onClick={submitForm}>
                  Add
                </ContainedButton>
              </DialogActions>
            </Form>
          )
        }}
      </Formik>
    </Dialog>
  )
}

export default (props: AddSignupsPromptProps) => (
  <ThemeProvider theme={muiTheme}>
    <AddSignupsPrompt {...props} />
  </ThemeProvider>
)
