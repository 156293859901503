import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { NullableTextCell, TimeCell, WithLinkCell } from 'core/table/cells'
import { DYNAMIC_TEXT } from 'core/table/filters'
import { createStringSort } from 'core/table/utils'
import { createDateValueSort } from 'components/table/sort'
import { DailyEventTableRow, OpportunityRegistrations } from 'Event/components/DailyEventsTable'
import { Cell, Column } from 'react-table'
import { ExpandRowCell } from 'core/table/cells/modern'
import { encodeOccurrenceFromEvent } from 'Event/helpers/encodeOccurrence'
import { useFeatureEnabled } from '../../core/feature/hooks'

export const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    cursor: 'pointer',
  },
  selectColumn: {
    overflow: 'visible',
    '&>div': {
      overflow: 'visible',
      width: '36px',
      '&>$subRow': {
        position: 'relative',
        left: '40px',
      },
    },
  },
  nameColumn: {
    '&>div': {
      width: '250px',
    },
  },
  timeColumn: {
    '&>div': {
      width: '150px',
    },
  },
  registrationsColumn: {
    '&>div>div': {
      width: '80px',
      textAlign: 'right',
      marginRight: '120px',
      '&$parentRow': {
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px',
        letterSpacing: '0.1px',
      },
    },
  },
  parentRow: {
    fontSize: '16px',
    fontFamily: 'Nunito',
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
  subRow: {
    '&$firstSubRow:after': {
      height: '30px',
      top: '-8px',
    },
    '&:after': {
      content: "''",
      position: 'absolute',
      width: '24px',
      height: '100%',
      top: '-20px',
      left: '-20px',
      borderBottom: '1px dashed #74777F',
      borderLeft: '1px dashed #74777F',
    },
  },
  firstSubRow: {},
}))

interface RegistrationCellProps extends Cell<DailyEventTableRow, OpportunityRegistrations> {
  parentRowClass: string
}

const RegistrationCell = ({ value: { total, confirmed }, row, parentRowClass }: RegistrationCellProps) => (
  <div className={isParentRow(row) ? parentRowClass : ''}>
    {confirmed} / {total}
  </div>
)

const isParentRow = (row: any) => row.canExpand

export default function useDailyEventsColumns() {
  const classes = useStyles()
  const bulkRegistrationEnabled = useFeatureEnabled('BulkRegistration')

  return useMemo(() => {
    const columns = [
      {
        id: 'expand',
        Cell: ExpandRowCell,
        hideInSelector: true,
      },
      {
        id: 'name',
        Header: 'Event Name',
        accessor: 'name',
        Cell: (props: any) => {
          if (isParentRow(props.row)) {
            return <div className={classes.parentRow}>{NullableTextCell(props)}</div>
          } else {
            return WithLinkCell(
              NullableTextCell,
              (row: DailyEventTableRow) => `/events/${encodeOccurrenceFromEvent(row)}/${row.organization?.id}`,
              // @ts-ignore
              null,
            )({ ...props, linkProps: { className: classes.link } })
          }
        },
        filter: DYNAMIC_TEXT,
        className: classes.nameColumn,
        sortType: createStringSort('name'),
      },
    ]
    if (bulkRegistrationEnabled) {
      columns.push({
        id: 'role',
        Header: 'Role',
        accessor: 'roleName',
        Cell: props => {
          if (isParentRow(props.row)) {
            return <div className={classes.parentRow}>{NullableTextCell(props)}</div>
          } else {
            return NullableTextCell(props)
          }
        },
        filter: DYNAMIC_TEXT,
        className: classes.nameColumn,
        sortType: createStringSort('roleName'),
      })
    }
    columns.push(
      ...[
        {
          id: 'startsAt',
          Header: bulkRegistrationEnabled ? 'Shift Start' : 'Event Start',
          accessor: 'startsAt',
          Cell: (props: any) => (
            <div className={isParentRow(props.row) ? classes.parentRow : ''}>{TimeCell(props)}</div>
          ),
          // @ts-ignore
          disableFilters: true,
          disableGlobalFilter: true,
          sortType: createDateValueSort('startsAt'),
          className: classes.dateColumn,
        },
        {
          id: 'endsAt',
          Header: bulkRegistrationEnabled ? 'Shift End' : 'Event End',
          accessor: 'endsAt',
          Cell: (props: any) => (
            <div className={isParentRow(props.row) ? classes.parentRow : ''}>{TimeCell(props)}</div>
          ),
          disableFilters: true,
          disableGlobalFilter: true,
          sortType: createDateValueSort('startsAt'),
          className: classes.timeColumn,
        },
        {
          id: 'registrations',
          Header: 'Registrations',
          accessor: 'registrations',
          Cell: (props: Cell<DailyEventTableRow, OpportunityRegistrations>) => (
            <RegistrationCell {...props} parentRowClass={classes.parentRow} />
          ),
          disableFilters: true,
          disableGlobalFilter: true,
          className: classes.registrationsColumn,
          disableSortBy: true,
        },
      ],
    )
    return columns
  }, [
    bulkRegistrationEnabled,
    classes.dateColumn,
    classes.link,
    classes.nameColumn,
    classes.parentRow,
    classes.registrationsColumn,
    classes.timeColumn,
  ]) as Column<DailyEventTableRow>[]
}
