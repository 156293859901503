import { isFunction } from 'lodash'
import React, { useMemo } from 'react'
import { Button, withStyles } from '@material-ui/core'
import { useFeatureEnabled } from 'core/feature/hooks'
import { useHistory } from 'react-router'

export const ApprovalButton = withStyles({
  root: {
    background: '#BFDF57',
    color: 'black',
  },
  label: {
    textTransform: 'uppercase',
  },
})(props => <Button variant="contained" {...props} />)

const useGroupDetailApprovalButtonCell = (onGotoApprovals, group) => {
  const newApproveUIEnabled = useFeatureEnabled('NewGroupApplicationPage')
  const history = useHistory()
  return useMemo(() => {
    const ApprovalButtonCell = ({ cell }) => {
      const handleClick = e => {
        if (newApproveUIEnabled && cell.row.values.status === 'Applicant') {
          history.push(`/groups/${group.id}/applications/${cell.row.original.person.id}`)
        } else {
          if (isFunction(onGotoApprovals)) onGotoApprovals(group, cell.row.values.status, cell.row.original.id)
        }
        e.preventDefault()
      }

      return cell.row.values.status === 'Applicant' ? (
        <ApprovalButton onClick={handleClick}>Approve</ApprovalButton>
      ) : (
        <Button onClick={handleClick} style={{ textDecoration: 'underline' }}>
          VIEW
        </Button>
      )
    }

    return ApprovalButtonCell
  }, [newApproveUIEnabled, onGotoApprovals, group]) // eslint-disable-line react-hooks/exhaustive-deps
}

export default useGroupDetailApprovalButtonCell
