import { useFetchRefactored } from '../../civic-champs-shared/api/hooks'
import { useCallback } from 'react'

export const useProcessGroupApplication = () => {
  const [request] = useFetchRefactored()

  return useCallback(
    async ({
      groupId,
      personIds,
      approve,
      comment,
    }: {
      groupId: number
      personIds: number[] | number
      approve: boolean
      comment?: string
    }) =>
      request({
        method: 'post',
        url: `/groups/${groupId}/membership_actions`,
        config: {
          body: {
            type: approve ? 'approved' : 'declined',
            personIds: Array.isArray(personIds) ? personIds : [personIds],
            comment: comment,
          },
        },
      }),
    [request],
  )
}
