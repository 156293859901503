export enum CredentialType {
  Waiver = 'waiver',
  Identification = 'identification',
}

export enum CredentialContentType {
  Text = 'text',
  Url = 'url',
}

export enum CredentialExpirationOption {
  Signup = 'signup',
  Day = 'day',
  Month = 'month',
  Year = 'year',
  Date = 'date',
  Updated = 'updated',
  DayOf = 'dayof',
  UserEntered = 'user_entered',
}

export interface CreateOrUpdateCredentialParam {
  title: string
  description: string
  contentType: CredentialContentType
  content: string
  url: string
  waiverStatement: string
  expirationOption: CredentialExpirationOption
  expirationOptionLength: number
  expirationDate?: string
  isRequired?: boolean
}

export interface WaiverFormValues extends Omit<CreateOrUpdateCredentialParam, 'expirationDate'> {
  expirationDate: Date
  statementType: 'custom' | 'default'
}

export interface Credential {
  id: number
  organizationId: number
  isPublished: boolean
  type: CredentialType
  createdAt: Date
  expirationOption: CredentialExpirationOption
  expirationOptionLength: number
  expirationDate?: Date
}
