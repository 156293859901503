import { useCallback, useEffect } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'

const STABLE_EMPTY_ARRAY = []

export const useMembers = (groupId, onResultUpdate) => {
  const [request, { result, loading, called }] = useFetch(
    'Error Fetching Group Members.  Please refresh the page and try again',
  )
  const refresh = useCallback(async () => {
    return request({
      onSuccess: result => {
        onResultUpdate && onResultUpdate(result)
      },
      method: 'get',
      url: `/groups/${groupId}/members`,
      config: {
        queryStringParameters: { statusCodes: ['member', 'applicant', 'probationary member'] },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request])
  useEffect(() => {
    if (groupId) {
      refresh()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])

  return {
    members: result || STABLE_EMPTY_ARRAY,
    loading: loading || !called,
    refresh,
  }
}
