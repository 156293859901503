import React, { useMemo, useState } from 'react'
import { Prerequisites, QuestionSet, QuestionSetPreReq } from '../types'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import { groupBy } from 'lodash'
import moment from 'moment'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import map from 'lodash/map'
import format from '../../utils/format'
import { QuestionAnswerSetsPagedView } from './QuestionAnswerSetsPagedView'

const useStyles = makeStyles({
  root: {
    display: 'block',
    padding: '0 24px',
  },
  panel: {
    '&$expanded': {
      margin: '0 0 12px 0',
      '&:before': {
        opacity: 1,
      },
    },
  },
  questionTitle: {
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    color: '#000000',
  },
  summary: {
    '&$expanded': {
      minHeight: '48px',
    },
  },
  expanded: {
    '& > $content': {
      margin: '12px 0',
    },
  },
  content: {},
})

const Accordion = ({
  name,
  byDate,
  defaultExpanded,
}: {
  name: string
  defaultExpanded?: boolean
  byDate: {
    [key: string]: QuestionSet[]
  }
}) => {
  const dates = useMemo(() => {
    return Object.keys(byDate).sort().reverse()
  }, [byDate])
  const [date, setDate] = useState(dates[0])
  const classes = useStyles()

  return (
    <ExpansionPanel
      style={{ boxShadow: 'none' }}
      classes={{ root: classes.panel, expanded: classes.expanded }}
      TransitionProps={{ unmountOnExit: true }}
      defaultExpanded={defaultExpanded}
    >
      <ExpansionPanelSummary
        classes={{ expanded: classes.expanded, content: classes.content, root: classes.summary }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${name}-content`}
        id={`${name}-header`}
      >
        <Typography className={classes.questionTitle}>{name}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails classes={{ root: classes.root }}>
        {dates.length > 1 && (
          <FormControl variant="outlined">
            <Select
              labelId={`${name}-select-label`}
              id={`${name}-select`}
              value={date}
              onChange={event => setDate(event.target.value as string)}
            >
              {map(dates, (date, id) => (
                <MenuItem key={id} value={date}>
                  {format.date(date)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <div style={{ clear: 'both' }} />
        <QuestionAnswerSetsPagedView
          prerequisites={{ questionSets: byDate[date] } as Prerequisites}
          fullHeight={false}
          autoHeight
          skipTitle
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

export const QuestionAnswerSetsGroupedView = ({
  questionSets,
  defaultExpanded,
}: {
  questionSets?: QuestionSetPreReq[]
  defaultExpanded?: boolean
}) => {
  const groupped: {
    [key: string]: {
      name: string
      byDate: {
        [key: string]: QuestionSetPreReq[]
      }
    }
  } = {}
  for (const [id, questionSubSets] of Object.entries(groupBy(questionSets, 'questionSetId'))) {
    groupped[id] = {
      name: questionSubSets[0].name,
      byDate: groupBy(questionSubSets, ({ answerSetCreatedAt }) => moment(answerSetCreatedAt).format('YYYY-MM-DD')),
    }
  }
  let i = 0
  return (
    <>
      {map(Object.values(groupped), ({ name, byDate }, id) => {
        return <Accordion key={id} name={name} byDate={byDate} defaultExpanded={defaultExpanded && i++ === 0} />
      })}
    </>
  )
}
