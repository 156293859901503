import { useFetchRefactored } from '../../civic-champs-shared/api/hooks'
import { useCallback } from 'react'
import { PersonRef } from '../../civic-champs-shared/common/types'
import { RegistrationResult, RoleWithShift } from '../interfaces'

export const useBulkRegisterVolunteers = () => {
  const [fetch] = useFetchRefactored<RegistrationResult[]>()
  return useCallback(
    (volunteers: PersonRef[], rolesWithShift: RoleWithShift[]) => {
      return fetch({
        method: 'put',
        url: '/bulk_register_volunteers',
        config: {
          body: {
            registrations: rolesWithShift.flatMap(({ roleId, timeshiftId, encodedOccurrence }) =>
              volunteers.map(({ id }) => ({
                roleId,
                timeshiftId,
                encodedOccurrence,
                personId: id,
              })),
            ),
          },
        },
      })
    },
    [fetch],
  )
}
