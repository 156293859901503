import { Field, Form, Formik } from 'formik'
import { FormModalScreen } from 'components/FormModal'
import React, { useCallback, useMemo } from 'react'
import { useAddEditEventStyles } from 'Event/hooks/useAddEditEventStyles'
import { OpportunityVisibility } from 'Event/interfaces'
import { Geofencing } from 'civic-champs-shared/core/location/utils'
import { Address, AddressField } from 'civic-champs-shared/formik/components/AddressField'
import * as yup from 'yup'
import { ArraySchema, NumberSchema, ObjectSchema } from 'yup'
import { StyledInput } from 'civic-champs-shared/formik/components/StyledInput'
import RadioGroupFormField from 'components/RadioGroupFormField'
import cn from 'classnames'
import { StyledAutocomplete } from 'components/StyledAutocomplete'
import { Group } from 'Event/components/opportunity/GroupPicker/types'
import { AssociationType, EventGroup } from 'Event/interfaces/interfaceCreateEditEvent'
import { useOptions } from 'Event/components/opportunity/GroupPicker/hooks'
import { CustomSwitch } from 'civic-champs-shared/formik/components'
import { CkEditorStyledField } from '../../civic-champs-shared/formik/components/CkEditorStyledField'
import { EMAIL_VALIDATION_TEXT } from 'champion/schema'
import AdvancedAutocomplete from 'civic-champs-shared/core/AdvancedAutocomplete'
import { OrganizationLocationReporting } from 'locations/types'
import { EventGeofencing, mapEventGeofencingToGeofencing } from 'utils/event'
import { DEFAULT_GEOFENCING } from 'civic-champs-shared/constants/GEO_DATA'
import { PersonRef } from '../../civic-champs-shared/common/types'
import VolunteerSelector from '../../tracking/activity/components/VolunteerSelector'
import { Person } from '../../people/interface'

export interface EventInfoScreenValues {
  name: string
  description: string
  address: string
  city: string
  state: string
  zip: string
  visibility: OpportunityVisibility
  geofencing: Geofencing
  visibilityGroups: EventGroup[]
  locationIsAddress: boolean
  locationDetails: string
  organizationLocationId: number | null
  isActive?: boolean
  contactEmail?: string
  contactName?: string
  contactPhoneNumber?: string
  useCustomAddress?: boolean
  pointOfContactPersons?: Person[]
}

interface FormValues {
  name: string
  description: string
  address: Address
  visibility: OpportunityVisibility
  visibilityGroups: EventGroup[]
  locationIsAddress: boolean
  locationDetails: string
  organizationLocationId: number | null
  isActive?: boolean
  contactEmail?: string
  contactName?: string
  contactPhoneNumber?: string
  useCustomAddress: boolean
  pointOfContactPersons?: Person[]
}

export interface EventInfoScreenProps {
  values: EventInfoScreenValues
  onSubmit: (values: EventInfoScreenValues, asDraft?: boolean) => void
  isNewEvent: boolean
  onBack: (values: EventInfoScreenValues) => void
  groups: Group[]
  isTemplate?: boolean
  isSchedulable?: boolean
  isDraftable: boolean
  namedLocations: OrganizationLocationReporting[]
}

const mapFormValuesToEventOverview = (
  values: FormValues,
  location?: OrganizationLocationReporting,
): EventInfoScreenValues => {
  let address = values.address.line1
  let city = values.address.city
  let state = values.address.state
  let zip = values.address.zip
  let geofencing = values.address.geofencing
  let locationIsAddress = values.locationIsAddress
  let locationDetails = values.locationDetails
  if (location) {
    address = location.line1 as string
    city = location.city as string
    state = location.state as string
    zip = location.zipCode as string
    geofencing =
      location.locationIsAddress && location.geofencing
        ? mapEventGeofencingToGeofencing(location.geofencing as EventGeofencing)
        : DEFAULT_GEOFENCING
    locationIsAddress = location.locationIsAddress
    locationDetails = location.locationDetails || ''
  }
  return {
    name: values.name,
    description: values.description,
    visibility: values.visibility,
    address,
    city,
    state,
    zip,
    geofencing,
    visibilityGroups: values.visibilityGroups,
    locationIsAddress,
    locationDetails,
    isActive: values.isActive,
    contactEmail: values.contactEmail,
    contactName: values.contactName,
    contactPhoneNumber: values.contactPhoneNumber,
    organizationLocationId: values.organizationLocationId,
    useCustomAddress: values.useCustomAddress,
    pointOfContactPersons: values.pointOfContactPersons,
  }
}

const validationSchema = yup.object({
  name: yup.string().required('A name is required. Please enter a name.'),
  description: yup.string().notRequired(),
  visibility: yup.string().oneOf(Object.values(OpportunityVisibility)),
  visibilityGroups: yup
    .array()
    .of(yup.object())
    .when('visibility', (visibility: OpportunityVisibility, schema: ArraySchema<ObjectSchema>) =>
      visibility === OpportunityVisibility.SELECT_GROUPS_ONLY
        ? schema.min(1, 'Please select at least one group.')
        : schema,
    ),
  organizationLocationId: yup.mixed().when('useCustomAddress', (useCustomAddress: boolean, schema: NumberSchema) => {
    return !useCustomAddress
      ? schema.required('A Location is required. Please select a Location, or use a custom address.')
      : schema.nullable()
  }),
  address: yup
    .object({
      line1: yup
        .string()
        .when(
          ['useCustomAddress', 'locationIsAddress'],
          (useCustomAddress: boolean, locationIsAddress: boolean, schema: ArraySchema<ObjectSchema>) =>
            useCustomAddress && locationIsAddress
              ? schema
                  .min(1, 'An address is required. Please enter an address.')
                  .required('An address is required. Please enter an address.')
                  .typeError('An address is required. Please enter an address.')
              : schema,
        ),
    })
    .when(
      ['useCustomAddress', 'locationIsAddress'],
      (useCustomAddress: boolean, locationIsAddress: boolean, schema: ArraySchema<ObjectSchema>) =>
        useCustomAddress && locationIsAddress
          ? schema
              .required('An address is required. Please enter an address.')
              .typeError('An address is required. Please enter an address.')
          : schema,
    ),
  locationDetails: yup.string().notRequired(),
  contactEmail: yup.string().email(EMAIL_VALIDATION_TEXT).trim().lowercase().notRequired().nullable(),
  contactName: yup.string().notRequired().nullable(),
  contactPhoneNumber: (yup.string() as any).phoneNumber().notRequired().nullable(),
})

export const EventInfoScreen = ({
  values,
  onSubmit,
  isNewEvent,
  onBack,
  groups,
  isSchedulable,
  isDraftable,
  isTemplate = false,
  namedLocations,
}: EventInfoScreenProps) => {
  const [asDraft, setAsDraft] = React.useState(false)
  const classes = useAddEditEventStyles()
  const initialValues: FormValues = useMemo(
    () => ({
      name: values.name,
      description: values.description,
      visibility: values.visibility,
      visibilityGroups: values.visibilityGroups,
      organizationLocationId: values.organizationLocationId,
      address: {
        line1: values.address,
        line2: '',
        city: values.city,
        state: values.state,
        zip: values.zip,
        country: '',
        county: '',
        geofencing: values.geofencing,
        utcOffset: 0,
      },
      locationIsAddress: values.locationIsAddress,
      locationDetails: values.locationDetails,
      isActive: values.isActive,
      contactEmail: values.contactEmail,
      contactName: values.contactName,
      contactPhoneNumber: values.contactPhoneNumber,
      useCustomAddress: !!values.useCustomAddress,
      pointOfContactPersons: values.pointOfContactPersons,
    }),
    [values],
  )

  const options = useOptions({ groups, associationType: AssociationType.EVENT_PRIVATE_TO_MEMBERS, value: [] })

  const handleBack = useCallback(
    (values: FormValues) => {
      onBack(mapFormValuesToEventOverview(values))
    },
    [onBack],
  )

  const handleSubmit = useCallback(
    (values: FormValues, asDraft: boolean) => {
      const location = namedLocations.find(({ id }) => id === values.organizationLocationId)
      onSubmit(mapFormValuesToEventOverview(values, location), asDraft)
    },
    [namedLocations, onSubmit],
  )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={values => handleSubmit(values, asDraft)}
      validateOnChange
      isInitialValid={!isNewEvent}
    >
      {({ submitForm, isSubmitting, values, setFieldValue, setFieldTouched, touched, errors }) => {
        return (
          <Form>
            <FormModalScreen
              onBack={() => handleBack(values)}
              onNext={submitForm}
              onDraft={
                isSchedulable && isDraftable
                  ? () => {
                      setAsDraft(true)
                      submitForm()
                    }
                  : undefined
              }
              draftText={isSchedulable && isDraftable ? 'Finish Later' : undefined}
              nextText="Next"
              backText={isTemplate ? 'Cancel' : 'Back'}
              disabled={isSubmitting}
            >
              <div className={classes.section}>
                <span className={classes.sectionTitle}>Event Details:</span>
                <Field
                  name="name"
                  component={StyledInput}
                  label={'Event Name *'}
                  placeholder="Write the event’s name here..."
                />
                {isTemplate && (
                  <div className={classes.switch}>
                    <Field
                      component={CustomSwitch}
                      id="isActive"
                      name="isActive"
                      thumbColor="#FFF"
                      trackColor={values.isActive ? '#008954' : '#a2a3a6'}
                      type="checkbox"
                      tooltip="Templates set to Active can be selected when creating opportunities. Templates set to Inactive will not be visible when creating new opportunities."
                    />
                    <span>{values.isActive ? 'Active' : 'Inactive'}</span>
                  </div>
                )}
                <Field
                  name="description"
                  label="Event Description"
                  placeholder="Describe the event here..."
                  component={CkEditorStyledField}
                />
              </div>
              <div className={cn(classes.section, classes.noMargin, classes.subSection)}>
                <span className={classes.sectionTitle}>Calendar Visibility:</span>
                <RadioGroupFormField
                  name="visibility"
                  disabled={isSubmitting}
                  options={[
                    {
                      label: 'This is a public event',
                      value: OpportunityVisibility.PUBLIC,
                    },
                    ...(isSchedulable
                      ? [
                          {
                            label: 'This is a private event',
                            value: OpportunityVisibility.PRIVATE,
                          },
                        ]
                      : []),
                    {
                      label: 'This event is only visible to selected Groups',
                      value: OpportunityVisibility.SELECT_GROUPS_ONLY,
                    },
                  ]}
                />
                {values.visibility === OpportunityVisibility.SELECT_GROUPS_ONLY && (
                  <Field
                    className={classes.subItem}
                    name="visibilityGroups"
                    placeholder="Select Group (s)"
                    label="Groups"
                    component={StyledAutocomplete}
                    options={options}
                    getOptionLabel={({ name, closed, approvedMembersOnly }: EventGroup) =>
                      !closed ? name : approvedMembersOnly ? `${name} - Approved members` : `${name} - Applicants`
                    }
                    disabled={isSubmitting}
                    multiple
                    notched={true}
                  />
                )}
              </div>
              <div className={cn(classes.section, classes.subSection)}>
                <span className={classes.sectionTitle}>Location:</span>
                <RadioGroupFormField
                  valueIsBoolean
                  name="useCustomAddress"
                  disabled={isSubmitting}
                  options={[
                    {
                      label: 'Select from a list of organization Locations',
                      value: false,
                    },
                  ]}
                />
                {!values.useCustomAddress && (
                  <div className={classes.subItem}>
                    <AdvancedAutocomplete<OrganizationLocationReporting>
                      label={'Event Location*'}
                      options={namedLocations}
                      placeholder={'Select a Location'}
                      value={namedLocations.find(({ id }) => id === values.organizationLocationId) || null}
                      getOptionInfo={location => ({
                        left: location.name as string,
                        id: location.id,
                      })}
                      onChange={organizationLocation =>
                        setFieldValue(
                          'organizationLocationId',
                          organizationLocation ? (organizationLocation as OrganizationLocationReporting).id : null,
                        )
                      }
                      onBlur={() => setFieldTouched('organizationLocationId')}
                      error={touched.organizationLocationId ? errors.organizationLocationId : undefined}
                    />
                  </div>
                )}
                <div className={cn({ [classes.unGap]: !values.useCustomAddress })}>
                  <RadioGroupFormField
                    valueIsBoolean
                    name="useCustomAddress"
                    disabled={isSubmitting}
                    options={[
                      {
                        label: 'Use a custom address',
                        value: true,
                      },
                    ]}
                  />
                </div>
                {values.useCustomAddress && (
                  <div className={classes.subItem}>
                    <RadioGroupFormField
                      valueIsBoolean
                      name="locationIsAddress"
                      disabled={isSubmitting}
                      options={[
                        {
                          label: 'This event takes place at a Google Maps address',
                          value: true,
                        },
                      ]}
                    />
                    {values.locationIsAddress && (
                      <Field
                        className={classes.subItem}
                        fullWidth
                        variant="outlined"
                        labelShrink
                        label={'Event Location' + isTemplate ? '' : ' *'}
                        placeholder="Select your event’s address here..."
                        component={AddressField}
                        name="address"
                        showRadius
                        vertical
                        halfMap
                        hideMap={!values.address.line1}
                      />
                    )}
                    <div className={cn({ [classes.unGap]: !values.locationIsAddress })}>
                      <RadioGroupFormField
                        name="locationIsAddress"
                        valueIsBoolean
                        disabled={isSubmitting}
                        options={[
                          {
                            label: 'This event is not tied to an address',
                            value: false,
                          },
                        ]}
                      />
                    </div>
                    {!values.locationIsAddress && (
                      <Field
                        component={StyledInput}
                        name="locationDetails"
                        className={classes.subItem}
                        label={'Event Location' + isTemplate ? '' : ' *'}
                        placeholder="Enter event location details here..."
                        maxLength={100}
                      />
                    )}
                  </div>
                )}
              </div>
              {isSchedulable && (
                <>
                  <div className={classes.section}>
                    <span className={classes.sectionTitle}>Primary Point of Contact: </span>
                    <Field
                      component={StyledInput}
                      name="contactName"
                      label={'Name'}
                      placeholder="Enter the Point of Contact’s name"
                    />
                    <Field
                      component={StyledInput}
                      name="contactEmail"
                      label={'Email'}
                      placeholder="Enter the Point of Contact’s email address"
                    />
                    <Field
                      component={StyledInput}
                      name="contactPhoneNumber"
                      label={'Phone'}
                      placeholder="Enter the Point of Contact’s phone number"
                    />
                  </div>
                  {!isTemplate && (
                    <div className={classes.section}>
                      <span className={classes.sectionTitle}>Point of Contact BCC: </span>
                      <VolunteerSelector
                        volunteers={values.pointOfContactPersons || []}
                        onChange={(value: PersonRef[]) => setFieldValue('pointOfContactPersons', value)}
                        placeholder="Select Recipients"
                        label={values.pointOfContactPersons?.length ? 'Recipients' : undefined}
                        maxLength={65}
                        maxWidth={600}
                      />
                    </div>
                  )}
                </>
              )}
            </FormModalScreen>
          </Form>
        )
      }}
    </Formik>
  )
}
